<template>
  <div>
    <my-header></my-header>
    <div class="table-box">
      <div>
        <span>已提交业务</span>
      </div>
      <el-table class="table" :data="item" border>
        <el-table-column
          property="title"
          label="项目标题"
          width="240"
        ></el-table-column>
        <el-table-column
          property="updateTime"
          label="更新时间"
          width="180"
        ></el-table-column>
        <el-table-column
          property="projectContact"
          label="项目联系人"
          width="120"
        ></el-table-column>
        <el-table-column
          property="projectContactPhone"
          label="项目联系人电话"
          width="140"
        ></el-table-column>
        <el-table-column
          property="address"
          label="业务所在地址"
          width="275"
        ></el-table-column>
        <el-table-column property="state" label="状态" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.state | state }}</span>
          </template>
        </el-table-column>
        <el-table-column property="type" label="业务类型" width="85">
          <template slot-scope="scope">
            <span>{{ scope.row.type | type }}</span>
          </template>
          ></el-table-column
        >
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="lookbusiness(scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.state == 1"
              type="primary"
              size="small"
              @click="resubmit(scope.row)"
              >重新提交</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";
export default {
  data() {
    return {
      item: [],
    };
  },
  filters: {
    type(val) {
      if (val == 0) {
        return "单人";
      } else if (val == 1) {
        return "多人";
      }else{
        return '不详';
      }
    },
    state(val) {
      if (val == 0) {
        return "待审核";
      } else if (val == 1) {
        return "失败";
      } else if (val == 2) {
        return "招募中";
      } else if (val == 3) {
        return "已完成";
      }
    },
    purchaseType(val) {
      if (val == 1) {
        return "厨房设备采购";
      } else if (val == 2) {
        return "厨房服务采购";
      } else if (val == 3) {
        return "食材采购";
      } else if (val == 4) {
        return "私有业务";
      }
    },
  },
  methods: {
    getItem() {
      this.item = JSON.parse(this.$route.query.item);
      console.log(this.item);
    },
    // 查看详情
    lookbusiness(v) {
      this.$router.push({
        path: "/wallBulletinSubmitDetail",
        query: {
          item: JSON.stringify(v),
        },
      });
    },
    // 重新提交
    resubmit(v) {
      this.$router.push({
        path: "/setInfoissue",
        query: {
          item: JSON.stringify(v),
        },
      });
    },
  },
  created() {
    this.getItem();
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  margin-top: 140px;

  .table {
    width: 80%;
    margin: 10px auto;
    margin-bottom: 50px;
  }
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
}
</style>